*,
*::before,
*::after {
   box-sizing: border-box;
}
* {
   margin: 0;
   padding: 0;
}

/* ------------------------------------------------------------ */

html {
   background: #07080f repeating-linear-gradient(-45deg, #0000 0 20px, #182431 20px 40px);
   min-height: 100%;
   font-family: sans-serif;
}

/* ------------------------------------------------------------ */

.inventar {
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   display: grid;
   align-items: center;
   justify-content: center;
   overflow: auto;
   padding: 20px;
   --cell-size: 60px;
}
.inventar-content {
   width: 1040px;
   height: 740px;
   pointer-events: auto;
   background: rgb(17 17 17 / 85%);
   color: #f5f5f7;
}
.inventar-sidebar {
   padding: 20px;
}

.inventar-title {
   padding: 0 10px 15px;
   font-size: 13px;
   font-weight: 600;
}
.inventar-middle {
   padding: 20px;
}
.inventar-middle .inventar-title {
   text-align: center;
}
.inventar-cells-wrapper {
   position: relative;
   width: max-content;
}
.inventar-cells {
   display: flex;
   flex-wrap: wrap;
   border: 1px solid #2d2e30;
   width: calc(var(--cell-size) * 5 + 2px);
}
.inventar-items {
   position: absolute;
   top: 1px;
   left: 1px;
   right: 1px;
   bottom: 1px;
   pointer-events: none;
}
.inventar-cell {
   width: var(--cell-size);
   height: var(--cell-size);
   border: 1px solid rgb(41 41 41 / 100%);
   user-select: none;
}
.inventar-cell[data-hovered="true"] {
   background-color: rgb(41 41 41 / 50%);
}
.inventar-item {
   pointer-events: auto;
   position: absolute;
   top: 0;
   left: 0;
   width: calc(var(--cell-size) * var(--width));
   height: calc(var(--cell-size) * var(--height));
   transform: translate(calc(var(--left) * var(--cell-size)), calc(var(--top) * var(--cell-size)));
   user-select: none;
   background-color: #292929c2;
}
.inventar-item[data-ghost][data-ignore-size] {
   width: var(--cell-size) !important;
   height: var(--cell-size) !important;
}

.inventar-item img {
   user-select: none;
   width: 100%;
   height: 100%;
   object-fit: contain;
   object-position: center;
   pointer-events: none;
}
.inventar-item[data-moving="true"] {
   opacity: 0.5;
}
.inventar-item[data-remove] {
   background-color: rgb(171 36 35 / 55%);
}
.inventar-content {
   display: flex;
   justify-content: space-between;
}

.inventar-places-top {
   --place-size: 60px;
   --place-gap: 4px;
   /* width: 300px; */
   height: calc(var(--place-size) * 5 + var(--place-gap) * 4);
   display: grid;
   grid-template-rows: repeat(5, 1fr);
   grid-template-columns: repeat(5, 1fr);
   grid-gap: var(--place-gap);
   position: relative;
}
.inventar-places-bottom {
   margin-top: 20px;
   --place-size: 60px;
   --place-gap: 4px;
   height: 400px;
   display: grid;
   grid-template-columns: repeat(6, minmax(0, 1fr));
   grid-gap: var(--place-gap);
   position: relative;
   place-content: start;
}
.inventar-places-bottom .inventar-place:nth-child(1) {
   height: 115px;
   width: 100%;
   grid-column: 1 / -1;
}
.inventar-places-bottom .inventar-place:nth-child(2),
.inventar-places-bottom .inventar-place:nth-child(3) {
   height: 110px;
   width: 100%;
   grid-column: span 3 / span 3;
}
.inventar-places-bottom .inventar-place:nth-child(4),
.inventar-places-bottom .inventar-place:nth-child(5),
.inventar-places-bottom .inventar-place:nth-child(6) {
   height: 90px;
   width: 100%;
   grid-column: span 2 / span 2;
}

.inventar-place {
   width: var(--place-size);
   height: var(--place-size);
   border: 2px solid rgb(41 41 41 / 100%);
   user-select: none;
   position: relative;
}
.inventar-place[data-hovered="true"] {
   background-color: rgb(41 41 41 / 50%);
}

.inventar-place .inventar-item {
   width: 100%;
   height: 100%;
   transform: none;
}

/* align them like
10001
10001
10001
10001
11111 */

.inventar-places-top .inventar-place:nth-child(1) {
   grid-row: 1 / 5;
   grid-column: 1 / 1;
}
.inventar-places-top .inventar-place:nth-child(2) {
   grid-row: 2 / 2;
   grid-column: 1 / 1;
}
.inventar-places-top .inventar-place:nth-child(3) {
   grid-row: 3 / 3;
   grid-column: 1 / 1;
}
.inventar-places-top .inventar-place:nth-child(4) {
   grid-row: 4 / 4;
   grid-column: 1 / 1;
}
.inventar-places-top .inventar-place:nth-child(5) {
   grid-row: 5 / 5;
   grid-column: 1 / 1;
}
.inventar-places-top .inventar-place:nth-child(6) {
   grid-row: 5 / 5;
   grid-column: 2 / 2;
}
.inventar-places-top .inventar-place:nth-child(7) {
   grid-row: 5 / 5;
   grid-column: 3 / 3;
}
.inventar-places-top .inventar-place:nth-child(8) {
   grid-row: 5 / 5;
   grid-column: 4 / 4;
}
.inventar-places-top .inventar-place:nth-child(9) {
   grid-row: 5 / 5;
   grid-column: 5 / 5;
}
.inventar-places-top .inventar-place:nth-child(10) {
   grid-row: 4 / 4;
   grid-column: 5 / 5;
}
.inventar-places-top .inventar-place:nth-child(11) {
   grid-row: 3 / 3;
   grid-column: 5 / 5;
}
.inventar-places-top .inventar-place:nth-child(12) {
   grid-row: 2 / 2;
   grid-column: 5 / 5;
}
.inventar-places-top .inventar-place:nth-child(13) {
   grid-row: 1 / 1;
   grid-column: 5 / 5;
}

.add-item-btn {
   position: fixed;
   bottom: 0;
   right: 0;
   appearance: none;
   background-color: white;
   color: black;
   border: none;
   width: 50px;
   height: 50px;
   z-index: 99;
}
